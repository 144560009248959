import React from "react";
import RedeemCodeForm from "./RedeemCodeForm";

const GamePurchase = ({
  thumbnailUrl,
  setIsLocked,
  setOpenPanel,
  description,
}) => {
  return (
    <>
      <div className="flex flex-col items-center mt-4 p-4">
        <img
          src={thumbnailUrl}
          alt="Thumbnail 1"
          className="w-full rounded-lg hover:opacity-70"
        />
        <p className="py-4 text-white">{description}</p>
      </div>
      <RedeemCodeForm setIsLocked={setIsLocked} setOpenPanel={setOpenPanel} />
    </>
  );
};

export default GamePurchase;
