import React, { useState } from "react";
import { Routes, Route, HashRouter, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import TermsOfService from "./pages/TermsOfService";
import Layout from "./pages/Layout";
import CookiesPolicy from "./pages/CookiesPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";

export const NotificationContext = React.createContext({});

function App() {
  const [notification, setNotification] = useState({
    message: "",
    open: false,
  });

  return (
    <BrowserRouter>
      <NotificationContext.Provider value={{ notification, setNotification }}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index={true} element={<Home />} />
            <Route path="termsofservice" element={<TermsOfService />} />
            <Route path="privacypolicy" element={<PrivacyPolicy />} />
            <Route path="cookiespolicy" element={<CookiesPolicy />} />
          </Route>
        </Routes>
      </NotificationContext.Provider>
    </BrowserRouter>
  );
}

export default App;
