import React, { useState } from "react";

import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import GamePurchase from "./GamePurchase";

const Game = ({ thumbnailUrl, name, link, description }) => {
  const [isLocked, setIsLocked] = useState(true);
  const [openPanel, setOpenPanel] = useState(false);
  var buttonText = isLocked ? "Unlock" : "Play";

  const handleClick = () => {
    if (isLocked) {
      setOpenPanel(true);
    } else {
      window.open(link, "_blank");
    }
  };

  return (
    <>
      <div className="flex flex-col items-center mt-4 p-4 w-440 h-340">
        <img
          src={thumbnailUrl}
          alt="Thumbnail 1"
          className="w-full rounded-lg hover:opacity-70"
        />
        <button
          type="button"
          className="rounded-md bg-indigo-500 w-full text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 px-4 py-2 mt-2"
          onClick={() => handleClick()}
        >
          {buttonText}
        </button>
      </div>

      <Transition.Root show={openPanel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setOpenPanel(false)}
        >
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-gray-800 py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                            {name}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-black text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpenPanel(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <GamePurchase
                          thumbnailUrl={thumbnailUrl}
                          setIsLocked={setIsLocked}
                          setOpenPanel={setOpenPanel}
                          description={description}
                        />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Game;
