import React from "react";
import Game from "./Game";

const GameGrid = () => {
  return (
    <>
      <div className="grid grid-cols-3 gap-4 bg-gray-900 px-10">
        <Game
          thumbnailUrl="/thumbnails/voucher-wheel-thumbnail.png"
          name="Voucher Wheel"
          link="/VoucherWheel"
          description="Test your luck and then challenge others!"
        />
        <Game
          thumbnailUrl="/thumbnails/space-invaders-thumbnail.png"
          name="Space Invaders"
          link="/Spaceinvaders"
          description="Retro style Arcade game. Shoot the ships before they shoot you"
        />
        <Game
          thumbnailUrl="/thumbnails/fishing-day-thumbnail.png"
          name="Fishing"
          link="/Fishing"
          description="Bait em! Catch em! Get Rich!"
        />
        {/* <Game
          thumbnailUrl="https://placehold.co/410x230"
          name="Bubble Shooter"
          link="/BubbleShooter"
          description="Classic! We know this'll make your favorites list"
        /> */}

        <Game
          thumbnailUrl="/thumbnails/air-hockey-thumbnail.png"
          name="Air Hockey"
          link="/airhockey"
          description="Our rendition of Ice Hockey. Play vs AI, or your friends!"
        />
        <Game
          thumbnailUrl="/thumbnails/connect-4-thumbnail.png"
          name="Connect 4"
          description="Connect 4 of your pieces to win! Play vs AI, or your friends!"
        />
      </div>
    </>
  );
};

export default GameGrid;
