function CookiesPolicy() {
  return (
    <div className="relative isolate pt-14">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="py-24 sm:py-32 lg:pb-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-32">
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Cookies Policy
            </h1>
          </div>
          <div className="py-8">
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              A cookie is a small file which asks permission to be placed on
              your computer’s hard drive. Once you agree, the file is added and
              the cookie helps analyse web traffic or lets you know when you
              visit a particular site. Cookies allow web applications to respond
              to you as an individual. The web application can tailor its
              operations to your needs, likes and dislikes by gathering and
              remembering information about your preferences.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              ​We use traffic log cookies to identify which pages are being
              used. This helps us analyse data about web page traffic and
              improve our website in order to tailor it to customer needs. We
              only use this information for statistical analysis purposes and
              then the data is removed from the system.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              ​Overall, cookies help us provide you with a better website, by
              enabling us to monitor which pages you find useful and which you
              do not. A cookie in no way gives us access to your computer or any
              information about you, other than the data you choose to share
              with us.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              ​You can choose to accept or decline cookies. Most web browsers
              automatically accept cookies, but you can usually modify your
              browser setting to decline cookies if you prefer. This may prevent
              you from taking full advantage of the website.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              You can also read more in our Privacy Policy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CookiesPolicy;
