import React, { useContext, useState } from "react";
import { NotificationContext } from "../App";

const RedeemCodeForm = ({ setIsLocked, setOpenPanel }) => {
  const [gameCode, setGameCode] = useState();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { setNotification } = useContext(NotificationContext);
  const RedeemCodes = [
    "mg2305",
    "mg23010",
    "mg23015",
    "mg23020",
    "mg230010",
    "mg230015",
    "mg230020",
    "mg2300250",
    "mg2300500",
    "mg23001000",
    "mg23001500",
    "mg23002000",
  ];
  //   mg2305 = 5 EUR
  // mg23010 = 10 eur
  // mg23015= 15 eur
  // mg23020 =20
  // mg20010 = 100
  // mg230015= 150
  // mg230020 = 200
  // mg2300250=250
  // mg2300500=500
  // mg23001000=1000
  // mg23001500=1.5k
  // mg23002000=2k

  const submitForm = () => {
    if (RedeemCodes.includes(gameCode) === false) {
      setShowErrorMessage(true);
      setGameCode("");
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 2000);
    } else {
      setIsLocked(false);
      setOpenPanel(false);
      setNotification({
        message: "Game has been redeemed.",
        open: true,
      });
    }
  };
  return (
    <>
      <form>
        <div class="mb-4">
          <label className="block text-white mb-2"></label>
          <input
            type="text"
            name="gameCode"
            class="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded focus:outline-none focus:border-blue-500"
            placeholder="Add game code"
            required
            value={gameCode}
            onChange={(e) => setGameCode(e.target.value)}
          />
        </div>
        <button
          type="button"
          onClick={submitForm}
          class="w-full bg-blue-500 text-white my-3 py-3  rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
        >
          Redeem
        </button>
        {showErrorMessage && (
          <div class="text-white mb-2 text-center p-2">
            Invalid code. Please try again.
          </div>
        )}
      </form>
    </>
  );
};

export default RedeemCodeForm;
