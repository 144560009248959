function PrivacyPolicy() {
  return (
    <div className="relative isolate pt-14">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="py-24 sm:py-32 lg:pb-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-32">
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Privacy Policy
            </h1>
          </div>
          <div className="py-8">
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              Hello! Welcome to the Dmultiversegames Privacy Policy. This policy
              describes how Dmultiversegames collects, uses, processes and
              shares information about you.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              This Privacy Policy applies to your download, access and/or use of
              Dmultiversegames Games, whether on your computer, on a mobile
              device, on our website 
              <span className="underline text-blue-500">
                www.dmultiversegames.com
              </span>
               (the &quot;Website&quot;) or any other website, device or
              platform (each a &quot;Game&quot; and together the “Games”). It
              also applies to our Marketing and Advertising activities on all
              platforms and other Services that we will provide to you from time
              to time including campaigns with influencers. This Privacy Policy
              also applies to any other Services that we may provide in relation
              to our Games or our Website, such as customer support, social
              media, community channels and other websites that we may operate
              from time to time (we refer to all our Games and other services
              collectively as our &quot;Services&quot;).
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              When you download, access and/or play our Games, or interact with
              our Websites or other Services you acknowledge the collection and
              use of your information in accordance with this Privacy Policy,
              including Dmultiversegames’s and our advertising partners’ use of
              your data for targeted advertising purposes as described in
              the Marketing and Advertising sections of this Privacy Policy.
              Those sections also explain how you can opt in or out of the use
              of your personal information for targeted advertising purposes. If
              you have any concerns about providing information to us, or it
              being used as described in this Privacy Policy, you should not use
              our Games or other Services.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              This Privacy Policy was last updated on 20.01.2024.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              Playing our Games
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              When you download, access and/or play our Games or use our other
              Services, we collect information about you. Most of this
              information comes directly from you or your device, such as device
              information, country, region, and game-play information. We use
              this information to provide our Games and Services to you,
              including for the purposes of optimisation, preventing fraud,
              customising in-game offers and verifying purchases. We will also
              use it for the other purposes set out in this Privacy Policy,
              including for Marketing and Advertising purposes.
            </p>

            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              Visiting our Websites
              <br></br>
              When you visit our Websites, such as 
              <span className="underline text-blue-500">
                https://community.dmultiversegames.com
              </span>
              ,
              <span className="underline text-blue-500">
                 https://dmultiversegames.com
              </span>
              ,  and other Websites operated by us (“Websites”), we collect
              information about you, such as details about how you use and
              interact with such Websites. We will use the information we
              collect about you for a number of purposes, including for
              operating and optimising the Websites, in connection with our
              advertising purposes, and for research or analysis.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              <strong> Dmultiversegames Profile and Playing Socially</strong>
              <br></br>
              You can choose to use a third party social network (such as
              Facebook) to add information to your Dmultiversegames Profile or
              to connect to your account. If you do this, then your personal
              information will be visible to other players and we’ll also use it
              for the other purposes set out in this Privacy Policy, including
              for Marketing and Advertising.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              <strong>Marketing</strong>
              <br></br>
              Where we record your preferences as permitting us to do so, (see
              further below on how to change your preferences) we and our
              third-party partners use information collected about you to send
              you marketing communications about our Games and other Services
              based on your interests. For example, we will send emails, serve
              ads within our Services or place ads on third party websites, apps
              and Internet-connected devices.
              <br></br>
              We will use the information that we hold about you to promote our
              Games and other Services or those of Dmultiversegames’s Group
              Companies in a number of ways. This might include:
              <ul className="list-disc list-inside py-2">
                <li>
                  advertising within third party websites, apps and
                  Internet-connect devices;
                </li>
                <li>
                  showing you promotions for other Dmultiversegames or its Group
                  Companies’ games in the Games you play; or
                </li>
                <li>sending you marketing materials by email.</li>
              </ul>
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              <strong>Advertising</strong>
              <br></br>
              Our Games include advertising for third party products and
              services.
              <br></br>
              Dmultiversegames and our advertising partners collect and use
              information about you to improve advertising, targeting and
              measurement systems so that we can show you relevant third party
              ads in our Games.
              <br></br>
              Dmultiversegames participates in the IAB Europe Transparency &amp;
              Consent Framework and complies with its Specifications and
              Policies. Dmultiversegames uses the Consent Management Platform
              (CMP) with the identification number 28.
              <br></br>
              Where applicable, we use a CMP to gather consent and permissions
              for our advertising based activity. Where local laws allow, we
              will opt you into targeted advertising by default. You can change
              your preferences at any time in the Privacy and Security settings
              of our Games.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              <strong>Ad identifiers, cookies and similar technologies</strong>
              <br></br>
              To provide our Games and other Services, Websites and targeted
              advertising, we use ad identifiers, cookies and similar
              technologies. This section describes our use of these technologies
              and how you can manage your preferences.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              <strong>Your rights</strong>
              <br></br>
              You have certain rights in connection with your personal
              information and how we handle it. You can exercise these rights at
              any time by contacting us via any of the methods set out in
              the Contact Us section below. Users located in the United States
              should review the US residents section below
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              <strong>Children</strong>
              <br></br>
              In accordance with our Terms of Use you must be over a certain age
              to play our Games and use our Services, depending on where you
              live. For the full list of age restrictions by country, please see
              below. We do not knowingly collect or solicit personal information
              from or direct or target interest-based advertising to anyone
              under the ages set out below or under 16 for California residents,
              or knowingly allow such persons to use our Games and Services. If
              you believe that we might have any information from or about a
              child under the age set out below, please Contact Us
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              <strong>General</strong>
              <br></br>
              This Privacy Policy contains further details about:
              <ul className="list-disc list-inside p-4">
                <li>
                  who we share your information with and the reasons for sharing
                  it;
                </li>
                <li>measures we take to keep your information secure;</li>
                <li>
                  the legal basis under which we process your information;
                </li>
                <li>
                  how long we retain your information and where we store it;
                </li>
                <li>
                  when and why we share your information with third parties,
                  including transfers outside of the European Economic Area (the
                  “EEA”), the UK, Switzerland and Canada (including Quebec); and
                </li>
                <li>changes or updates to our Privacy Policy.</li>
              </ul>
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              <strong>Contact us</strong>
              <br></br>
              If you have any questions or concerns about any of the matters in
              relation to this Privacy Policy, including to exercise any of your
              rights, then there are several ways of contacting us:
              <ul className="list-decimal list-inside p-4">
                <li>
                  via our customer service page at
                  <span className="underline text-blue-500">
                     https://soporto.dmultiversegames.com/contact
                  </span>
                  ;
                </li>
                <li>if you have questions, please check our FAQs;</li>
                <li>
                  via email to 
                  <span className="underline text-blue-500">
                    privacy@support.dmultiversegames.com
                  </span>
                  ; or
                </li>
                <li>
                  in writing to Multiverse MK Ltd of 128 City Road, EC1V 2NX,
                  London, UK.
                </li>
              </ul>
              For information collected under this Privacy Policy, the data
              controller is Multiverse MK Limited. Our Data Protection Officer
              can be contacted here:{" "}
              <span className="underline text-blue-500">
                dataprotectionofficer@dmultiversegames.com
              </span>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
