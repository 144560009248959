function TermsOfService() {
  return (
    <div className="relative isolate pt-14">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="py-24 sm:py-32 lg:pb-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-32">
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Terms of Use
            </h1>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              1 About these Terms
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              Please read these Terms of Use (the &quot;Terms&quot;) carefully.
              These Terms apply to your download, access and/or use of
              Dmultiversegames games, whether on your computer, on a mobile
              device, on our website 
              <span className="underline text-blue-500">
                dmultiversegames.com
              </span>
              (the &quot;Website&quot;) or any other website, device or platform
              (each a &quot;Game&quot; and together the &quot;Games&quot;).
              These Terms also apply to any other services that we may provide
              in relation to the Games or the Website, such as customer support,
              social media, community channels and other websites that we may
              operate from time to time such as 
              <span className="underline text-blue-500">
                dmultiverseallgames.com
              </span>
               (we refer to all our Games and other services collectively as the
              &quot;Services&quot; in these Terms). These Terms are a legal
              agreement and contain important information about your rights and
              obligations in relation to our Services.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              1.1 If you do not agree to these Terms or any future updated
              version of them then you must not access and/or use, and must
              cease all access and/or use of, any of our Services. If we require
              that any future update to these Terms requires any action from you
              in order to accept the updated Terms, then you may not be able to
              continue to use the Services until you have taken such action.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              1.2 These Terms represent a legal agreement between you and
              Multiverse MK Limited, a United Kingdom company with registered
              office address at 128 City Road, EC1V 2NX, London, UK (company
              registration number 14507138). The parties intend that the Terms
              of this agreement inure to the benefit of Dmultiversegames&#39;s
              Affiliates (as defined in paragraph 21.2 below).
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              1.3 By click dmultiversegames &quot;Accept&quot; on the Game&#39;s
              pop-up screen, or installation page, Dmultiversegames&#39;s
              webpage, or Dmultiversegames&#39;s Community Forum (or where such
              button or a similar button is otherwise presented) or downloading,
              installing, accessing or using the Services, you confirm that:
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              1.3.1 you have read and understood these Terms and any other
              documents referred to herein, including without limitation
              our Privacy Policy, and that you agree to be bound by these Terms;
              and
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              1.3.2 you are at least 13 years old, or else you must not use our
              Services. If you are the parent or guardian of a minor aged 13 or
              older (depending on the applicable laws of your country), you are
              agreeing to these Terms on your behalf and on behalf of your
              minor(s) who you have authorised to use the Service pursuant to
              these Terms.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              1.4 FOR RESIDENTS IN THE USA: IMPORTANT NOTICE: THESE TERMS ARE
              SUBJECT TO BINDING ARBITRATION AND A WAIVER OF CLASS ACTION RIGHTS
              AS DETAILED BELOW IN PARAGRAPH 21.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              1.5 In these Terms references to &quot;Dmultiversegames&quot;,
              &quot;we&quot;, &quot;us&quot; and &quot;our&quot; are references
              to Dmultiversegames.com Limited as well as Dmultiversegames.com
              Limited&#39;s Affiliates where appropriate.
            </p>

            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              1.5 In these Terms references to &quot;Dmultiversegames&quot;,
              &quot;we&quot;, &quot;us&quot; and &quot;our&quot; are references
              to Dmultiversegames.com Limited as well as Dmultiversegames.com
              Limited&#39;s Affiliates where appropriate.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              1.5 In these Terms references to &quot;Dmultiversegames&quot;,
              &quot;we&quot;, &quot;us&quot; and &quot;our&quot; are references
              to Dmultiversegames.com Limited as well as Dmultiversegames.com
              Limited&#39;s Affiliates where appropriate.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              1.6 These Terms are made available via the app store or platform
              that you download our Games from (such as the Apple App Store,
              Google Play Store, or Amazon App Store or the Microsoft Store), on
              any website or platform where you can play our Games such as{" "}
              <span className="underline text-blue-500">www.facebook.com</span>
               . You agree that by accessing and/or using our Services, you are
              agreeing to these Terms and our Privacy Policy (see paragraph 13
              below).
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              1.7 YOU MUST BE AND HEREBY AFFIRM THAT YOU ARE AN ADULT OF THE
              LEGAL AGE OF MAJORITY IN YOUR COUNTRY OR STATE OF RESIDENCE. If
              you are under the legal age of majority and over the age of 13,
              your parent or legal guardian must consent to these Terms as set
              out in paragraph 1.3.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              1.8 You can access the latest version of these Terms at any time
              at dmultiversegames.com/TermsAndConditions. We can make changes to
              these Terms at any time in accordance with paragraph 17 below and,
              except in relation to any amendment to paragraph 21 (Binding
              Arbitration and Class Action waiver) below, your continued use of
              our Services after the Terms have been updated shall confirm your
              acceptance of the updated Terms.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              2 About Accessing and Using our Services
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              2.1 The specific game rules, scoring rules, controls and
              guidelines for each Game can be found within the Game itself. Such
              rules, scoring rules, controls and guidelines form part of these
              Terms and you agree that you will comply with them in respect of
              each individual Game which you choose to access and/or play.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              2.2 You are responsible for the internet connection and/or mobile
              charges that you may incur for accessing and/or using our
              Services. You should ask your mobile operator or internet service
              provider if you are unsure what these charges will be, before you
              access and/or use our Services.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              2.3 There may be times when our Services or any part of them are
              not available for technical or maintenance related reasons,
              whether on a scheduled or unscheduled basis. See paragraph 8 below
              for more information.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              3 Accounts
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              3.1 When using our Services you may choose to, and in some
              instances you will be required to, create an account with us
              (&quot;Dmultiversegames Account&quot;). If you do create a
              Dmultiversegames Account with us, you agree that you will take all
              steps necessary to protect your log in details and keep them
              secret. If you do not create a Dmultiversegames Account, your game
              play and any Virtual Content purchased or earned will not be
              accessible from any other device, and will not be restored to a
              different device if you lose, damage or change your device.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              3.2 You agree that you will not give your login details to anyone
              else or allow anyone else to use your login details or
              Dmultiversegames Account.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              3.3 In these Terms, references to &quot;login details&quot; or
              &quot;Dmultiversegames Account&quot; include your login details
              and account for any social network or platform that you may allow
              our Services to interact with.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              3.4 We will be entitled to assume that anyone logging into your
              Dmultiversegames Account using your login details is you and not
              anybody else. If you fail to keep your login details secret, or if
              you share your login details or Dmultiversegames Account with
              someone else (whether intentionally or unintentionally), you
              accept full responsibility for the consequences of this (including
              any unauthorised purchases) and agree to fully compensate us for
              any losses or harm that may result.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              3.5 We will not be responsible to you for any loss that you suffer
              as a result of any other person accessing your Dmultiversegames
              Account and/or using our Services and we accept no responsibility
              for any losses or harm resulting from its unauthorised use,
              whether fraudulently or otherwise.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              3.6 We reserve the right to delete your Dmultiversegames Account
              if no activity is conducted by you in relation to the
              Dmultiversegames Account for 180 or more days. In such an event,
              you may no longer be able to access and/or use any Virtual Money
              and/or Virtual Goods (as defined below) associated with that
              Dmultiversegames Account and no refund will be offered to you in
              relation to the same.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              3.7 You understand that if you delete your Dmultiversegames
              Account, or if we delete your Dmultiversegames Account in
              accordance with these Terms, you may lose access to any data
              previously associated with your Dmultiversegames Account
              (including, without limitation, your progress through our Games
              and/or the level or score you have reached in our Games and any
              Virtual Money or Virtual Goods associated with your
              Dmultiversegames Account). We have no obligation to reinstate your
              Dmultiversegames Account or be liable for any loss of data if you
              delete your Dmultiversegames Account, or if we delete your
              Dmultiversegames Account in accordance with these Terms.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              3.8 YOU ACKNOWLEDGE AND AGREE THAT YOU WILL HAVE NO OWNERSHIP OR
              OTHER PROPERTY INTEREST IN ANY DMULTIVERSEGAMES ACCOUNT THAT YOU
              CREATE USING ANY OF OUR SERVICES. TO THE FULLEST EXTENT
              PERMISSIBLE BY APPLICABLE LAW, WE MAY SUSPEND, TERMINATE, MODIFY
              OR DELETE ANY DMULTIVERSEGAMES ACCOUNT AT ANY TIME FOR ANY REASON
              (SUCH AS CEASING A GAME FOR ECONOMIC REASONS DUE TO A LIMITED
              NUMBER OF USERS CONTINUING TO MAKE USE OF THE GAME OVERTIME) OR NO
              REASON, WITH OR WITHOUT NOTICE TO YOU.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              3.9 Your Dmultiversegames Account is personal to you and you are
              not entitled to transfer your Dmultiversegames Account to any
              other person.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              4 Virtual Content
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              4.1 Our Games may include virtual currencies such as gold bars and
              gems (&quot;Virtual Money&quot;), items or services for use within
              our Games (&quot;Virtual Goods&quot;) or paid subscriptions for
              Virtual Money, Virtual Goods or other in-game features
              (&quot;Subscriptions&quot;). Virtual Goods, Virtual Money and
              Subscriptions shall together be referred to in these Terms as
              &quot;Virtual Content&quot;. To benefit from or use some Virtual
              Content in our Games, you may first have to reach a certain level
              or progress to a certain point. To the fullest extent permitted
              under applicable law, you agree that (once purchased) Virtual
              Content has no monetary value and can never be exchanged for real
              money, real goods or real services from us or anyone else. You
              agree that Virtual Content is not transferable to anyone else and
              you will not transfer or attempt to transfer any Virtual Content
              to anyone else. For the purposes of this paragraph 4, a
              &quot;purchase&quot; is complete at the time our servers validate
              your purchase and the applicable Virtual Content is successfully
              credited to your account on our servers.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              4.2 You do not own Virtual Content but instead you purchase a
              limited personal revocable licence to use them. To the fullest
              extent permitted under applicable law, any inclusion or balance of
              Virtual Content does not reflect any stored value.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              4.3 Prices for all Virtual Content exclude all applicable taxes
              and telecommunication charges, unless otherwise indicated. To the
              fullest extent required by law, you agree to pay any such
              applicable taxes and telecommunication charges in line with
              applicable law.
            </p>

            <h2 className="text-xl font-bold tracking-tight text-white sm:text-xl px-8 py-4">
              Virtual Money and Virtual Goods
            </h2>

            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              4.4 You agree that all sales by us to you of Virtual Money and/or
              Virtual Goods are final, that we will not refund any transaction
              once it has been made. Notwithstanding the foregoing, if you are
              resident in the European Economic Area, you have the right to
              withdraw from the purchase and the associated purchase terms
              (which may be set out by the applicable payment intermediary for
              the purchase, for example Google or Apple) within fourteen (14)
              calendar days. However, as a resident in the European Economic
              Area, you agree that when you purchase Virtual Goods and/or
              Virtual Money from us, you request immediate performance and that
              you understand this means you will lose your statutory right to
              withdraw from your purchase and the associated terms once you
              access and use the Virtual Goods and/or Virtual Money. If you are
              resident in the United Dmultiversegamesdom, when you purchase
              Virtual Goods and/or Virtual Money and we immediately supply the
              Virtual Goods and/or Virtual Money to you, you will be asked to
              acknowledge that this means you will lose your statutory right to
              withdraw from your purchase and the associated terms.
            </p>

            <h2 className="text-xl font-bold tracking-tight text-white sm:text-xl px-8 py-4">
              Connecting your game play
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              4.5 If you do not connect your Game on a device to a
              Dmultiversegames account, we will not be able to restore any
              Virtual Content, or other data associated with your Game to a
              different device if you lose, damage or change that device.
              Accordingly, on a device which is not connected to your
              Dmultiversegames Account:
              <ul className="list-disc list-inside p-4">
                <li>
                  any risk of loss of Virtual Content which you purchase from us
                  is transferred to you upon completion of the purchase as
                  described in paragraph 4.1 above;
                </li>
                <li>
                  any risk of loss of Virtual Content that you receive from us
                  without madmultiversegames a purchase is transferred to you at
                  the time the Virtual Content is successfully credited to your
                  account on our servers; and
                </li>
                <li>
                  any risk of loss of Virtual Content that you receive from us
                  without madmultiversegames a purchase is transferred to you at
                  the time the Virtual Content is successfully credited to your
                  account on our servers; and
                </li>
              </ul>
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              4.6 The data associated with Virtual Content (whether purchased by
              you using real money or otherwise credited or awarded to you) is
              synced between different devices only if you have connected your
              Game to a Dmultiversegames Account. Accordingly, any risk of loss
              of this data is transferred to you (i) in the case of Virtual
              Content which you purchase from us, upon completion of the
              purchase as described in paragraph 4.1 above; and/or (ii) in other
              cases at the time the Virtual Content is credited or awarded to
              you.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              4.7 If you live in the European Economic Area and the United
              Kingdom, we will provide you with a VAT invoice where we are
              required to do so by law or where expressly requested by you. You
              agree that these invoices may be electronic in format.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              4.8 We reserve the right to control, regulate, change or remove
              any Virtual Content without any liability to you at any time, but
              if you have an active Subscription such changes will only take
              effect following the end of the then-current Subscription period,
              at which date your Subscription will automatically terminate.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              4.9 We may revise the pricing for Virtual Goods and Virtual Money
              at any time. We may also revise the pricing for Subscriptions
              offered through the Services with reasonable notice to you and in
              accordance with applicable law, but if you have an active
              Subscription such changes will only take effect following the end
              of the then-current Subscription period. When we give you notice,
              we will remind you that your continued use after the end of your
              then-current Subscription period means that you accept the new
              Subscription prices. To the maximum extent permitted by applicable
              law, if you don&#39;t cancel before the price changes come into
              force, you will be deemed to have accepted those modifications. If
              you are resident in Germany, nothing in this paragraph 4.9 is
              intended to affect your statutory rights, and we will enact any
              price revisions in accordance with applicable law.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              4.10 We may limit the total amount of Virtual Content that may be
              purchased at any one time, and/or limit the total amount of
              Virtual Content that may be held in your account in the aggregate.
              You are only allowed to obtain Virtual Content from us or our
              authorised partners through the Services, and not in any other
              way.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              4.11 Depending on your platform, any Virtual Content purchased may
              be subject to your platform provider&#39;s terms of service and
              user agreement. Usage rights for each purchase may differ from
              item to item. If you are unsure about usage rights you should
              check with your platform or our customer support team before
              madmultiversegames a purchase. Unless otherwise shown, content
              available in any in-game store has the same age rating as the
              Game.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              4.12 Without limiting paragraphs 3.7, 3.8, 4.5, or 7.1, if we
              suspend or terminate your account in accordance with these Terms
              you may lose any Virtual Goods and/or Virtual Money that you may
              have and we will not compensate you for this loss or make any
              refund to you. If you have an active Subscription at the date of
              termination it will not auto-renew at the end of the then-current
              Subscription period.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              4.13 The charge for any Virtual Content that you can purchase via
              our Services will be as stated in our Services at the time you
              place the order, except in the case of obvious error. The charge
              is inclusive of all sales taxes and other charges. Depending on
              which bank you use, additional charges may be issued by your bank;
              we have no control over this and accept no liability in relation
              to the same. If you are unsure whether you will be subject to such
              additional charges then you should check with your bank before
              madmultiversegames a purchase. We accept payment via our payment
              processing partners by credit card, debit card, carrier billing
              and any other payment method we expressly authorise during the
              payment process. Our payment processing partners may have their
              own terms and conditions and you should ensure you agree with
              these prior to madmultiversegames any payment. If your transaction
              with our payment processing partners is not successful then your
              purchase will not be fulfilled. Upon the completion of a
              successful payment transaction then your purchase will be
              fulfilled to you as soon as possible - we will endeavour to fulfil
              your order immediately at the point of purchase.
            </p>

            <h2 className="text-xl font-bold tracking-tight text-white sm:text-xl px-8 py-4">
              Subscriptions
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              4.14 Payment for a Subscription will be charged to your account at
              the point of purchase and, for renewals, will be taken within the
              24 hours prior to the expiry of the then-current Subscription
              period. Your Subscription will automatically renew on the basis
              communicated to you in writing unless you turn off auto-renew via
              your platform settings at least 24 hours before the end of the
              then-current Subscription period. Your Subscription is linked to
              your platform account and cannot be transferred between platform
              accounts. You agree that sales of Subscriptions are final and that
              cancellation is not permitted mid-way during an active
              Subscription period (subject to paragraph 4.15 below if you are
              resident in the European Economic Area).
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              4.15 For residents of the European Economic Area, if you have
              purchased a new Subscription, you have the right to withdraw from
              the purchase and the applicable purchase terms within fourteen
              (14) calendar days for any reason, subject to the below. This
              right of withdrawal does not apply to renewals of your existing
              Subscription. Your right of withdrawal starts from the day after
              your purchase of the Subscription. Instructions on how to exercise
              this right are set out at the end of these Terms in Appendix 1
              (Section A: &#39;Information concerning the exercise of the right
              of withdrawal&#39;). For residents of the United Kingdom and
              countries outside of the European Economic Area, when you purchase
              a Subscription from us and receive immediate access to it, you
              will be asked to acknowledge that this means you will lose your
              statutory right to withdraw from the purchase.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              5 User Conduct and Content
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              5.1 You must comply with the laws that apply to you in the
              location that you access our Services from. If any laws applicable
              to you restrict or prohibit you from using our Services, you must
              comply with those legal restrictions and, if applicable, stop
              accessing and/or using our Services.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              5.2 You promise that all the information you provide to us on
              accessing and/or using our Services is and will remain true,
              accurate and complete at all times.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              5.3 Information, data, software, sound, photographs, graphics,
              video, messages, tags, usernames, guild names or other materials
              may be sent, uploaded, communicated, transmitted or otherwise made
              available via our Services by you or another user
              (&quot;Content&quot;). You understand and agree that all Content
              that you may be sent when using our Services, whether publicly
              posted or privately sent, is the sole responsibility of the person
              that sent the Content. This means that you, not us, are entirely
              responsible for all Content that you may upload, communicate,
              transmit or otherwise make available via our Services.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              5.4 You agree not to upload, communicate, transmit or otherwise
              make available any Content or otherwise use the Services in any
              way:
              <ul className="list-disc list-inside p-4">
                <li>
                  that is or could reasonably be viewed as unlawful, harmful,
                  harassing, grooming, defamatory, libellous, obscene or
                  otherwise objectionable;
                </li>
                <li>
                  that is or could be reasonably viewed as toxic and/or abusive
                  behaviour, or behaviour that is intended to unreasonably
                  undermine or disrupt the Game experiences of others or affect
                  other players;
                </li>
                <li>
                  that is or could reasonably be viewed as invasive of
                  another&#39;s privacy or violate any privacy rights;
                </li>
                <li>
                  that is likely to, or could reasonably be viewed as likely to
                  incite violence, terrorist activities or racial or ethnic
                  hatred;
                </li>
                <li>
                  which you do not have a right to make available lawfully (such
                  as inside information, information which belongs to someone
                  else or confidential information);
                </li>
                <li>
                  which infringes any intellectual property right or other
                  proprietary right of others;
                </li>
                <li>
                  which consists of any unsolicited or unauthorised advertising,
                  promotional materials, &#39;junk mail&#39;, &#39;spam&#39;,
                  &#39;chain letters&#39;, &#39;pyramid schemes&#39; or any
                  other form of solicitation; or
                </li>
                <li>
                  which contains software viruses or any other computer code,
                  files or programs designed to interrupt, destroy or limit the
                  functionality of any computer software or hardware or
                  telecommunications equipment.
                </li>
              </ul>
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              5.5 You agree that you will not:
              <ul className="list-disc list-inside p-4">
                <li>
                  use our Services to harm anyone or to cause offence to or
                  harass any person, or otherwise use our Services to act in a
                  way that is intended to unreasonably undermine or disrupt the
                  Game experiences of others or affect other players (including,
                  without limitation, engaging in toxic or abusive behaviour);
                </li>
                <li>
                  use the Services or any third-party systems to commit an
                  illegal or fraudulent action that is prohibited under
                  applicable laws and/or under these Terms;
                </li>
                <li>
                  exploit, in its entirety or individual components, the
                  Services for any purpose not expressly authorised by us,
                  including, without limitation:
                  <ul className="list-disc list-inside p-4">
                    <li>playing the Game(s) at commercial establishments;</li>
                    <li>
                      gathering in-game currency, items, or resources for
                      sale/selling/exchanging outside of our Services;
                    </li>
                    <li>
                      performing in-game services including, without limitation,
                      account boosting or power-levelling, in exchange for
                      payment or otherwise;
                    </li>
                    <li>
                      communicating or facilitating (by text, live audio
                      communications, or otherwise) any commercial
                      advertisement, solicitation or offer through or within the
                      Services; or
                    </li>
                    <li>
                      organising, promoting, facilitating, or participating in
                      any event involving wagering on the outcome, or any other
                      aspect of, our Games, whether or not such conduct
                      constitutes gambling under the laws of any applicable
                      jurisdiction, without authorisation.
                    </li>
                  </ul>
                </li>
                <li>
                  create more than one Dmultiversegames Account per platform to
                  access our Services;
                </li>
                <li>
                  share your Dmultiversegames Account or login information with
                  anyone, unless these Terms allow it;
                </li>
                <li>
                  use another person or entity&#39;s email address in order to
                  sign up to use our Services;
                </li>
                <li>
                  use our Services for fraudulent or abusive purposes
                  (including, without limitation, by using our Services to
                  impersonate any person or entity, or otherwise misrepresent
                  your affiliation with a person, entity or our Services);
                </li>
                <li>
                  disguise, anonymise or hide your IP address or the source of
                  any Content that you may upload;
                </li>
                <li>
                  use our Services for any commercial or business purpose or for
                  the benefit of any third party or to send unsolicited
                  communications;
                </li>
                <li>
                  remove or amend any proprietary notices or other ownership
                  information from any part of our Services;
                </li>
                <li>
                  interfere with or disrupt our Services or servers or networks
                  that provide our Services;
                </li>
                <li>
                  attempt to decompile, reverse engineer, disassemble or hack
                  any of our Services, or to defeat or overcome any of our
                  encryption technologies or security measures or data
                  transmitted, processed or stored by us;
                </li>
                <li>
                  &#39;harvest&#39;, &#39;scrape&#39; or collect any information
                  about or regarding other people that use our Services,
                  including, but not limited to any personal data or information
                  (including by uploading anything that collects information
                  including but not limited to &#39;pixel tags&#39; cookies,
                  graphics interchange formats (&#39;gifs&#39;) or similar items
                  that are sometimes also referred to as &#39;spyware&#39; or
                  &#39;pcms&#39; (passive collection mechanisms);
                </li>
                <li>
                  sell, transfer or try to sell or transfer a Dmultiversegames
                  Account or any part of a Dmultiversegames Account, any Virtual
                  Content (including, without limitation, any sale of Virtual
                  Money and/or Virtual Goods for real money or for other value
                  outside of the relevant Game);
                </li>
                <li>
                  disrupt the normal flow of a Game or otherwise act in a manner
                  that is likely to negatively affect other players&#39; ability
                  to compete fairly when playing our Games or engaging in real
                  time exchanges;
                </li>
                <li>
                  disobey any requirements or regulations of any network
                  connected to our Services;
                </li>
                <li>
                  use our Services in violation of any applicable law or
                  regulation;
                </li>
                <li>
                  use our Services to cheat or design or assist in cheating (for
                  example, by using automated means or third party software to
                  play), or to otherwise circumvent technological measures
                  designed to control access to, or elements of, our Services,
                  or to do anything else that a reasonable person is likely to
                  believe is not within the spirit of fair play or these Terms;
                </li>
                <li>
                  be abusive, aggressive, demeaning or conduct offensive
                  behaviour towards Dmultiversegames&#39;s customer service
                  representatives by any means like chat, email, phone or any
                  other means of communication; or
                </li>
                <li>
                  use our Services in any other way not permitted by these
                  Terms.
                </li>
                If you are concerned that someone else is not complying with any
                part of these Terms, please contact us here: 
                <span className="underline text-blue-500">
                  reportabuse@support.dmultiversegames.com
                </span>
              </ul>
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              5.6 We do not control Content posted on our Services by other
              people and therefore we do not guarantee the accuracy, integrity
              or quality of that Content. You understand that when using our
              Services, you may be exposed to Content that you may consider
              offensive, indecent or objectionable. To the fullest extent
              permitted under applicable law, under no circumstances will we be
              liable in any way for any Content, including, but not limited to,
              any errors or omissions in any Content, or any losses or harm of
              any kind resulting from the use of any Content posted, emailed,
              transmitted or otherwise made available via our Services.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              5.7 We have the right to remove uploaded Content from our Services
              if we decide in our sole discretion that it results in or from a
              breach of any part of these Terms, or that it may bring us or our
              Services into disrepute. However, you acknowledge that we do not
              actively monitor Content that is contributed by people that use
              our Services and we make no undertaking to do so.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              5.8 You are solely responsible for your interactions with other
              users of our Services. You agree that you will not hold
              Dmultiversegames liable for any loss or damage arising from the
              Content (yours or another user&#39;s) including without limitation
              in respect of any defamation, harassment, or false endorsement
              claims.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              6 Playing our Games with other users
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              6.1 Some of our Games allow you to play against an opponent or to
              play socially with other users. You may be able to:
              <ul className="list-inside p-4">
                <li>
                  6.1.1 choose to play against another user or to play socially
                  with another user whom Dmultiversegames selects for you, or
                </li>
                <li>
                  6.1.2 play against, or play socially with, one of your
                  contacts on a platform or social network which you have
                  allowed our Games to interact with. Some of our Games may also
                  allow you to search for your friends (for example, by email
                  address) in order to find them to play against or play
                  socially with. We may also display the display names of your
                  past opponents so that you can easily find them to play again.
                </li>
              </ul>
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              6.2 Where Dmultiversegames selects another user or opponent for
              you, or groups you with other users for a game mode, we may either
              select at random or use such criteria as we see fit in order to
              make these selections (for example, your past scores, your
              country, or the level you have reached in the Game or your other
              gameplay activity).
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              6.3 By accessing and/or playing our Games you agree that your
              display name, scores, avatar, country location, online/offline
              status and other related details may be used and displayed in any
              and all media (whether it exists now or in the future), for any
              purpose, in perpetuity, without any payment to you, including
              (without limitation) to other users in our Games or within our
              marketing. You also understand that other users may find you by
              searching for you with your email address, only if another user
              already knows your email address to search for you. Please note
              that we will only show your display name publicly, and not your
              email address. For more information, please read our Privacy
              Policy.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              6.4 Some of our Games may include functionality that may allow you
              to participate in text with other users. You may be able to
              participate in text chat with other players, send messages to
              Dmultiversegames customer services or publicly on our forums.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              7 Your breach of these Terms
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              7.1 Without limiting any other remedies or any other paragraph of
              these Terms, if we reasonably believe that you are in material
              breach of these Terms (including by repeated minor breaches), we
              reserve the right to take any of the following actions, whether
              individually or in combination, and either with or without notice
              to you:
              <ul className="list-disc list-inside p-4">
                <li>
                  delete, suspend and/or modify your Dmultiversegames Account,
                  parts of your account and/or access to our Games;
                </li>
                <li>
                  otherwise suspend and/or terminate your access to our
                  Services;
                </li>
                <li>
                  correct, modify and/or remove any Virtual Content (including
                  but not limited to Virtual Goods or Virtual Money) or game
                  features that may be associated with your account;
                </li>
                <li>
                  correct, reset and/or modify any game progression, game
                  features or benefits and privileges associated with your
                  account, such as any level or score you have reached in our
                  Games.
                </li>
              </ul>
              We may terminate the licence granted to you under these Terms
              and/or suspend, modify, or delete your account at any time without
              giving you any prior notice if you materially breach our Terms.
              Without limitation, any breaches of paragraphs 4.1, 4.10, 5.4 or
              5.5 are likely to be considered material breaches.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              7.2 You agree to compensate us, according to applicable law, for
              all losses, harm, claims and expenses that may arise from any
              breach of these Terms by you.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              7.3 If you think that a decision to suspend, modify or delete your
              account was taken wrongly, please contact us at 
              <span className="underline text-blue-500">
                queries@support.dmultiversegames.com
              </span>
              . We will restore any Virtual Content and/or game features if we
              have good cause to believe our actions were not sufficiently
              justified.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              7.4 We will notify you if we decide to terminate or suspend any
              licence we have granted to you under these Terms, unless: we are
              reasonably unable to notify you due to technical limitations; we
              are terminating or suspending it to comply with a legal obligation
              which does not include a prior notice obligation; notification is
              not possible due to a legal obligation to take immediate action;
              or if we reasonably believe that providing notice is likely to
              materially harm a third party.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              8 Availability of the Services
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              8.1 For residents in the United States: We do not guarantee that
              any of our Services will be available at all times or at any given
              time or that we will continue to offer all or any of our Services
              for any particular length of time. We may change and update our
              Services without notice to you. We make no warranty or
              representation regarding the availability of our Services and
              reserve the right to modify or discontinue the Services in our
              sole discretion without notice, including for example, ceasing a
              Game or other Service for economic reasons due to a limited number
              of users continuing to make use of that Game or other Service over
              time, for technical reasons (such as technical difficulties
              experienced by us or on the internet) or to allow us to improve
              user experience. NOTWITHSTANDING ANYTHING TO THE CONTRARY, YOU
              ACKNOWLEDGE AND AGREE THAT ANY OR ALL OF OUR SERVICES MAY BE
              TERMINATED IN WHOLE OR IN PART AT OUR SOLE DISCRETION WITHOUT
              NOTICE TO YOU. YOU ASSUME ANY AND ALL RISK OF LOSS ASSOCIATED WITH
              THE TERMINATION OF OUR SERVICES.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              8.2 For residents outside the United States and the European
              Economic Area: We do not guarantee that any of our Services will
              be available or error-free at all times or at any given time but
              we will provide our Services in accordance with any legally
              required standards. In particular, in relation only to any Virtual
              Content or any other part of our Services which have been paid-for
              with real money, we warrant that they will substantially comply
              with the description provided by it at the point of purchase and
              be of satisfactory quality (in addition any related services
              provided through them will be provided with reasonable care and
              skill). We may change and update our Services in whole or in part
              without notice to you (provided that any such changes do not
              result in material degradation in the functionality of any part of
              the Services which has been paid-for with real money). We make no
              warranty or representation regarding the availability of the
              Services which are provided free of charge (i.e. not paid-for with
              real money) and we reserve the right to modify or discontinue them
              in our sole discretion without notice to you, including for
              example, for economic reasons due to a limited number of users
              continuing to make use of them over time, for technical reasons
              (such as technical difficulties experienced by us or on the
              internet) or to allow us to improve user experience. If such
              circumstances result in material and significant degradation in
              the functionality of the Services then any obligation you may have
              to make any payment to download, use or access them will be
              suspended for the duration of such period. Notwithstanding the
              foregoing, we are not liable or responsible for any failure to
              perform, or delay in performance of, any of our obligations that
              are caused by events outside our reasonable control. You also
              acknowledge and agree that we are not liable for any errors
              relating to Services which are provided wholly by a third party
              (for example, a community channel hosted and operated by a third
              party).We are entitled to modify or discontinue the Services or
              any part of them which are paid-for with real money in our sole
              discretion upon reasonable notice to you.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              8.3 For residents in the European Economic Area: Notwithstanding
              the fact that we do not guarantee that any of our Services will be
              available, uninterrupted, or error-free at all times or at any
              given time, and you agree that we are not liable or responsible
              for any failure to perform, or delay in performance of, any of our
              obligations that are caused by events outside our reasonable
              control, your local laws may provide you with a legal guarantee
              that the Services will be in legal conformity at the time of
              supply and during the life of these Terms with you. Under this
              legal guarantee, we will be liable for lack of conformity of the
              Games and/or Virtual Content and you may have a right under your
              local laws to: (a) have the Games and/or Virtual Content brought
              back into conformity; or (b) a proportionate refund and/or
              termination of these Terms (or the applicable purchase terms if
              you&#39;ve purchased the Games and/or Virtual Content from a third
              party). Other than this legal guarantee and to the fullest extent
              permitted by applicable law, no other warranties of any kind,
              express or implied, are included as part of these Terms. If you
              are resident in France, the Terms in Appendix 1 (Section C) apply
              to your statutory guarantee. To the fullest extent permissible
              under applicable law, you also acknowledge and agree that we are
              not liable for any errors relating to any Services which are
              provided wholly by a third party (for example, a community channel
              hosted and operated by a third party). We may change and update
              our Services in whole and in part, in order to, for example
              (without limitation):
              <ul className="list-disc list-inside p-4">
                <li>
                  ensure compliance with applicable laws and/or reflect change
                  in relevant laws and regulatory requirements;
                </li>
                <li>
                  perform temporary maintenance, fix bugs, implement technical
                  adjustments, and make improvements;
                </li>
                <li>
                  update or upgrade our Services including updating the
                  structure, design, or layout of our Services;
                </li>
                <li>
                  release new Services, end support for older Services, or close
                  down certain Services if we have good reason to do so;
                </li>
                <li>balance, maintain, improve, update, or renew a Service;</li>
                <li>ensure the security of our Services; and</li>
                <li>
                  to combat illegal and/or harmful activities, including the use
                  of unauthorised programs or other activities which breach
                  these Terms.
                </li>
              </ul>
              Unless otherwise required by applicable laws and provided that any
              such updates do not result in material derogation in the
              functionality of the Services, you grant us consent to deploy and
              apply such patches, updates, and modifications and we may update
              the Services remotely without notifying you. If any updates to our
              Services result in a negative impact to your access or use of the
              Services in a more than minor way, we will give you notice of such
              changes. If you don&#39;t agree to the changes, you will be able
              to terminate these Terms within 30 days from the date of any
              notice we provide, or 30 days from when the change comes into
              effect, whichever is later. If you are resident in Germany, we
              will give you at least six (6) weeks&#39; notice of such changes,
              and you will have 30 days from our notice of the change or from
              when the change comes into effect (whichever is later) to reject
              the changes and terminate these Terms. For residents in the
              European Economic Area, you may also be able to receive a
              proportionate refund for aspects of the Services, such as
              Subscriptions, paid for but not received. Please contact us at 
              <span className="underline text-blue-500">
                queries@support.dmultiversegames.com
              </span>
               for more information. If you do not object to the changes or
              terminate these Terms within the stated time limit, we will take
              that as your acceptance of the changes. For avoidance of doubt,
              nothing in these Terms will preclude us from discontinuing the
              Services or any part of them, provided that we provide sufficient
              notice to you as required by applicable laws.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              9 For residents in the United States - Limitation of Liability and
              Indemnity
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              9.1 Limitation of Liability: TO THE FULLEST EXTENT PERMITTED BY
              APPLICABLE LAW AND NOTWITHSTANDING ANYTHING TO THE CONTRARY IN
              THESE TERMS, OUR SERVICES ARE &quot;AS IS&quot; AND WE DO NOT
              WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE,
              THAT DEFECTS WILL BE CORRECTED, THAT SERVICES ARE FREE OF VIRUSES
              OR OTHER HARMFUL COMPONENTS, OR THAT ALL OF THE CONTENT OF THE
              SERVICES WILL BE ACCURATE. TO THE EXTENT PERMITTED BY APPLICABLE
              LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
              INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF
              MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE OR USE, AND
              NON-INFRINGEMENT. ADDITIONALLY, TO THE FULLEST EXTENT PERMITTED BY
              APPLICABLE LAW, NEITHER DMULTIVERSEGAMES NOR ITS AFFILIATES WILL
              BE LIABLE IN ANY WAY FOR DAMAGE OR LOSS OF ANY KIND RESULTING FROM
              POSSESSION, USE, OR MALFUNCTION OF THE SERVICES (HOWEVER CAUSED
              AND REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT, OR
              OTHERWISE), INCLUDING DAMAGES TO PROPERTY, COMPUTER FAILURE OR
              MALFUNCTION AND, TO THE FULLEST EXTENT PERMITTED BY LAW, DAMAGES
              FOR PERSONAL INJURIES, EVEN IF WE HAVE BEEN ADVISED OF THE
              POSSIBILITY OF THESE DAMAGES. IN NO EVENT AND UNDER ANY THEORY OF
              LIABILITY SHALL OUR LIABILITY EXCEED THE LESSER OF (A) THE ACTUAL
              PRICE PAID BY YOU (IF ANY) FOR THE LICENCE TO USE VIRTUAL GOODS OR
              VIRTUAL MONEY OR ANY OTHER PART OF OUR SERVICES OR; (B) THE AMOUNT
              YOU PAID TO US DURING THE TWELVE (12) MONTHS PRIOR TO THE TIME
              YOUR CAUSE OF ACTION AROSE. SOME STATES DO NOT ALLOW LIMITATIONS
              ON HOW LONG AN IMPLIED WARRANTY LASTS AND/OR THE EXCLUSION OR
              LIMITATION OF DAMAGES, SO THE ABOVE LIMITATIONS AND/OR EXCLUSIONS
              MAY NOT APPLY TO YOU. THIS WARRANTY GIVES YOU SPECIFIC LEGAL
              RIGHTS, AND YOU MAY HAVE OTHER RIGHTS WHICH VARY FROM JURISDICTION
              TO JURISDICTION.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              9.2 Indemnification: YOU AGREE, TO THE EXTENT PERMITTED BY LAW, TO
              INDEMNIFY AND HOLD DMULTIVERSEGAMES, ITS DIRECTORS, OFFICERS,
              EMPLOYEES, PARENT, SUBSIDIARIES, AFFILIATES, AGENTS, CONTRACTORS,
              AND LICENSORS HARMLESS WITH RESPECT TO ANY CLAIMS or demand,
              including attorneys&#39; fees, arising out of your breach or
              alleged breach of these Terms, YOUR VIOLATION OF RULES FOR OUR
              GAMES, COMPETITIONS, OR TOURNAMENTS, or your violation of any law
              or the rights of a third party. Nothing in these Terms shall be
              deemed to exclude or limit your liability in respect of any
              indemnity given by you under these Terms. YOU FURTHER AGREE THAT
              YOU SHALL NOT SUE OR RECOVER ANY DAMAGES FROM DMULTIVERSEGAMES,
              ITS DIRECTORS, OFFICERS, EMPLOYEES, PARTNER, SUBSIDIARIES,
              AFFILIATES, AGENTS, CONTRACTORS, AND LICENSORS AS A RESULT OF ITS
              DECISION TO REMOVE OR DENY ACCESS TO ANY SERVICE, TO SUSPEND OR
              TERMINATE YOUR ACCESS TO THE SERVICE, OR TO TAKE ANY OTHER ACTION
              DURING THE INVESTIGATION OF A SUSPECTED VIOLATION OR AS A RESULT
              OF Dmultiversegames&#39;s CONCLUSION THAT A VIOLATION OF THESE
              TERMS HAS OCCURRED. THIS WAIVER AND INDEMNITY PROVISION APPLIES TO
              ALL VIOLATIONS DESCRIBED IN OR CONTEMPLATED BY THESE TERMS.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              10 For residents outside the United States - Limitation of
              Liability
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              10.1 We accept liability for death or personal injury resulting
              from our negligence or that of our employees or agents, and for
              losses or harm caused by fraud by us or our agents, or any other
              liability which may not by law be excluded.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              10.2 We are not responsible for:
              <ul className="list-disc list-inside p-4">
                <li>
                  losses or harm not caused by our breach of these Terms or
                  negligence;
                </li>
                <li>
                  losses or harm which are not reasonably foreseeable by you and
                  us at the time of you agreeing to these Terms including those
                  losses which happen as a side effect of foreseeable losses.
                  This could include loss of data, loss of opportunity, service
                  interruption, computer or other device failure or financial
                  loss;
                </li>
                <li>
                  any damage that may be caused to any device on which you
                  access or use any of our Services that is caused in any way by
                  our Services unless that damage is directly caused by our
                  failure to exercise reasonable skill and care in the provision
                  of the applicable Services;
                </li>
                <li>
                  any loss or damage, or any increase in loss or damage
                  resulting from breach by you of any of these Terms;
                </li>
                <li>
                  any use by you of the Services which isn&#39;t authorised by
                  us under these Terms;
                </li>
                <li>
                  any lack of functionality or failure to provide any part of
                  the Services that is due to:
                  <ul className="list-disc list-inside py-4 px-8">
                    <li>
                      malfunctions or faults in your chosen equipment, devices,
                      operating system, or internet connection (including
                      malware, viruses or bugs originating from third parties or
                      any of your devices); or
                    </li>
                    <li>
                      your failure to download or install any update or the most
                      recent published version of any Service in order to
                      benefit from updated functionality or to meet
                      compatibility requirements, where we have informed you of
                      the update, explained the consequences of failing to
                      install the update, and provided installation
                      instructions;
                    </li>
                  </ul>
                </li>
                <li>
                  any lack of functionality or incompatibility where your chosen
                  equipment, devices, operating system or internet connection
                  does not meet the specific requirements provided by us; or
                </li>
                <li>
                  technical failures or the lack of availability of any of our
                  Services where these are not within our reasonable control.
                </li>
              </ul>
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              10.3 For any losses or harm (other than those mentioned in
              paragraph 10.1 and subject to paragraph 10.2) in any period of
              twelve months, to the fullest extent permitted under applicable
              law, we will only be responsible for losses or harm which are a
              reasonably foreseeable consequence of our negligence or breach of
              these Terms up to the value of the amount that you have paid to us
              in the 100 day period ending on the date of your claim. Losses and
              harm are foreseeable only where they could be contemplated by you
              and us at the time of you agreeing to these Terms.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              10.4 Subject to applicable law and unless otherwise specified in
              these Terms, we do not give any warranty, express or implied, in
              relation to our Services and you acknowledge that your only right
              with respect to any problems or dissatisfaction with any of our
              Services is to discontinue your use of our Services. Any
              additional legal rights, such as any applicable statutory rights
              of legal conformity (for example, see paragraph 8.3 above) which
              you may have as a consumer, remain unaffected by these Terms.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              11 Intellectual Property
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              11.1 You acknowledge that all copyright, trademarks, and other
              intellectual property rights in and relating to our Services
              (other than Content which is contributed and owned by players) is
              owned by or licensed to us.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              11.2 Whilst you are in compliance with these Terms, we grant you a
              non-exclusive, non-transferable, personal, revocable limited
              licence to access and/or use our Services (but not any related
              object or source code) for your own personal private use, in each
              case provided that such use is in accordance with these Terms. You
              agree not to use our Services for anything else. These Terms also
              apply to any update or patches which we may release or make
              available for any of the Services and any such update or patch
              will be deemed part of the Services for the purposes of these
              Terms.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              11.3 YOU ACKNOWLEDGE AND AGREE THAT, OTHER THAN THE LICENCE
              GRANTED TO YOU BY THESE TERMS, YOU WILL HAVE NO OWNERSHIP OR
              PROPERTY INTEREST IN ANY OF OUR SERVICES, INCLUDING WITHOUT
              LIMITATION ANY DMULTIVERSEGAMES ACCOUNTS OR ANY VIRTUAL CONTENT.
              You must not copy, distribute, make available to the public or
              create any derivative work from our Services or any part of our
              Services unless we have first agreed to this in writing.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              11.4 In particular, and without limiting the application of
              paragraph 11.3, you must not make use or available any cheats or
              technological measures designed to control access to, or elements
              of, our Services, including providing access to any Virtual
              Content, whether on a free of charge basis or otherwise.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              11.5 By submitting Content (as defined in paragraph 5.3) via our
              Services you:
              <ul className="list-disc list-inside p-4">
                <li>are representing that you are fully entitled to do so;</li>
                <li>
                  represent and warrant that you are fully able to grant the
                  licences set out in this paragraph 11.5;
                </li>
                <li>
                  represent and warrant that the Content does not infringe upon
                  the copyright, trademark, patent, trade secret or other rights
                  of any third party;
                </li>
                <li>
                  grant us a perpetual, irrevocable, worldwide, fully paid up,
                  non-exclusive, sub- licensable, royalty free, right and
                  licence to exploit the Content and all elements thereof, in
                  any and all media, formats and forms, known now or hereafter
                  devised;
                </li>
                <li>
                  grant us the unlimited right to edit, adapt, translate,
                  reformat, manufacture, rearrange and transpose, distribute,
                  sell, sub-licence, transmit, provide access to, publish and
                  use the Content you submitted, any derivative works we may
                  create from it and any elements contained therein, in any and
                  all media (whether it exists now or in the future), for any
                  purpose, in perpetuity, without any payment to you;
                </li>
                <li>
                  acknowledge that you may have what are known as &quot;moral
                  rights&quot; in the Content, for example the right to be named
                  as the creator of your entry and the right not to have work
                  subjected to derogatory treatment. To the maximum extent
                  permitted by applicable law, you agree to waive any such moral
                  rights you may have in the Content or, if a waiver is not
                  possible under applicable law, not to assert such moral
                  rights; and
                </li>
                <li>
                  agree that we have no obligation to monitor or protect your
                  rights in any Content that you may submit to us, but you do
                  give us the right to enforce your rights in that Content if we
                  want to, including but not limited to taking legal action (at
                  our cost) on your behalf.
                </li>
              </ul>
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              11.4 In particular, and without limiting the application of
              paragraph 11.3, you must not make use or available any cheats or
              technological measures designed to control access to, or elements
              of, our Services, including providing access to any Virtual
              Content, whether on a free of charge basis or otherwise.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              11.6 You must not copy, distribute, make available to the public
              or create any derivative work from any Content belonging to any
              other user of our Services. If you believe that your intellectual
              property rights in the Content have been infringed by another user
              of our Services, and the infringed Content is hosted on our
              Services that are under our direct control, you may contact us by
              emailing the following information to 
              <span className="underline text-blue-500">
                ip- notice@dmultiversegames.com
              </span>
               with:
              <ul className="list-disc list-inside p-4">
                <li>
                  a description of the intellectual property rights and an
                  explanation as to how they have been infringed;
                </li>
                <li>
                  a description of where the infringing material is located;
                </li>
                <li>your address, phone number and email address;</li>
                <li>
                  a statement by you, made under penalty of perjury, that (i)
                  you have a good- faith belief that the disputed use of
                  material in which you own intellectual property rights is not
                  authorised, and (ii) the information that you are providing is
                  accurate, correct, and that you are authorised to act on
                  behalf of the owner of an exclusive right that is allegedly
                  infringed; and
                </li>
                <li>
                  a physical or electronic signature of the person authorised to
                  act on behalf of the owner of the exclusive right that has
                  allegedly been infringed.
                </li>
              </ul>
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              11.7 For avoidance of doubt and without prejudice to the remainder
              of this paragraph 11, in the event you upload or otherwise
              transmit to us any Content that contains concepts, ideas, or
              feedback relating to any Game and/or our Services
              (&quot;Feedback&quot;), you will not be entitled to any
              compensation for any such Feedback, and we may freely use any such
              Feedback in any manner we deem appropriate. In addition, any
              Feedback by you will not create any contractual relationship
              between us. Except to the extent that any such waiver is
              prohibited by applicable law, you hereby waive the benefit of any
              provision of law known as &quot;moral rights&quot; or &quot;droit
              moral&quot; or any similar law in any country of the world or, in
              the event that a waiver is not possible under applicable law, you
              agree not to assert such moral rights.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              12 For residents in the United States - U.S. GOVERNMENT RESTRICTED
              RIGHTS:
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              12.1 Our Services have been developed entirely at private expense
              and are provided as &quot;Commercial Computer Software&quot; or
              &quot;restricted computer software.&quot; Use, duplication or
              disclosure by the U.S. Government or a U.S. Government
              subcontractor is subject to the restrictions set forth in
              subparagraph (b)(1)(ii) of the Rights in Technical Data and
              Computer Software clauses in DFARS 252.227-7013 or as set forth in
              subparagraph (b)(1) and (2) of the Commercial Computer Software
              Restricted Rights clauses at FAR 52.227-19, as applicable. The
              Contractor/Manufacturer is Dmultiversegames.com Ltd, Aragon
              Business Centre, Level 4, Dragonara Road, St Julians STJ3140,
              Malta.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              13 Privacy
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              13.1 The data controller for all personally identifiable
              information or personal data that we collect about you through or
              in relation to our Games or other Services is Dmultiversegames.com
              Limited.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              13.2 Dmultiversegames.com Limited collect, process, use and share
              your personal information in accordance with our Privacy
              Policy and as set out in these Terms. If you do not agree to
              our Privacy Policy you should not download or access our Games or
              otherwise access and/or use our Services.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              14 Links
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              14.1 We may link to third party websites or services from our
              Services. You understand that we make no promises regarding any
              content, goods or services provided by such third parties and we
              do not endorse the same. We are also not responsible to you in
              relation to any losses or harm caused by such third parties. Any
              charges you incur in relation to those third parties are your
              responsibility. You understand that when you provide data to such
              third parties you are providing it in accordance with their
              privacy policy (if any) and our own privacy policy does not apply
              in relation to that data.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              15 Transferring these Terms
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              15.1 To the fullest extent permissible under applicable law, we
              may wish to transfer all or a part of our rights or
              responsibilities under these Terms to someone else without
              obtaining your consent. You agree that we may do so provided that
              the transfer does not significantly disadvantage you. You may not
              transfer any of the rights we give you under these Terms unless we
              first agree to this in writing.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              16 Entire agreement
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              16.1 These Terms, all terms incorporated into these Terms (such as
              Dmultiversegames&#39;s Privacy Policy), and any other terms as
              agreed between you and Dmultiversegames (for example, any rules we
              create that govern in-game competitions or tournaments) together
              set out the entire agreement between you and us concerning our
              Services (as defined in paragraph 1) and they supersede, replace
              and extinguish all earlier agreements and understandings between
              you and us. You acknowledge that Dmultiversegames and its
              Affiliates have not made any actual or implied promise,
              representation, or warranty whatsoever, and acknowledge that you
              have not agreed to these Terms, played Dmultiversegames&#39;s
              games, played in Dmultiversegames&#39;s competitions or
              tournaments, obtained Virtual Content, or accepted
              Dmultiversegames&#39;s Services in reliance upon any such promise,
              representation or warranty, that is not expressly contained
              herein.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              17 Changes to these Terms
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              17.1 You can find these Terms at any time by visiting our Terms of
              service in the web.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              17.2 Without affecting paragraph 17.3 below, we reserve the right
              to update these Terms from time to time by posting the updated
              version at that address (and where these updates affect you, with
              reasonable notice to you, as required under applicable law). We
              may do so for a number of reasons including without limitation
              because we change the nature of our products or services, for
              technical or legal reasons, or because the needs of our business
              have changed. Your continued use of our Services will mean that
              you accept the amended Terms. You agree that if you do not accept
              any amendment to our Terms then you must immediately stop
              accessing and/or using our Services.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              17.3 If you are resident in Germany, we may from time to time
              modify or change these Terms, if there is good reason for the
              change (such as compliance with legal requirements, minor
              clarifications to existing Terms, to provide further information
              on issues that were unforeseeable at the time you entered into
              these Terms, or to improve how we deliver the Services to you) and
              the changes reasonably take into account the interests of both
              parties, but we will not change our main obligations to you. Where
              any changes affect you, we will notify you of any changes by
              providing you with a notice. To the maximum extent permitted by
              local law, if you do not object to the amended Terms within six
              (6) weeks following the notice, your continued use of our Services
              will mean that you accept the amended Terms.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              18 Severability
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              18.1 If any part of these Terms is held to be invalid or
              unenforceable under any applicable laws or by an applicable court,
              that part will be interpreted in a manner consistent with
              applicable law to reflect as nearly as possible our original
              intentions and the remainder of these Terms will remain valid and
              enforceable. If it is not possible to interpret an invalid or
              unenforceable part of these Terms in a manner consistent with
              applicable law, then that part will be deemed deleted from these
              Terms without affecting the remaining provisions of these Terms.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              19 Waivers of our rights
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              19.1 Our failure to exercise or enforce any of our rights under
              these Terms does not waive our right to enforce such rights. Any
              waiver of such rights will only be effective if it is in writing
              and signed by us.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              20 Complaints and dispute resolution
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              20.1 Most concerns can be solved quickly by contacting us at 
              <span className="underline text-blue-500">
                queries@support.dmultiversegames.com
              </span>
              .
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              20.2 If you are a resident of the European Union, if you are not
              satisfied with the resolution of your request by our customer
              service, the European Commission provides an online dispute
              resolution Platform which is accessible at
              <span className="underline text-blue-500">
                http://ec.europa.eu/consumers/odr
              </span>
              .
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              20.3 If you are a resident of the United Dmultiversegamesdom and
              you are not satisfied with the resolution of your request by our
              customer service, the United Dmultiversegamesdom Government
              provides information regarding consumer rights at
              <span className="underline text-blue-500">
                https://www.gov.uk/consumer-protection-rights
              </span>
              .
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              20.4 If you are resident in the United States, please see
              paragraph 21 below.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              20.5 In the unlikely event that we cannot solve your concern and
              you wish to bring legal action against us, the provisions of
              paragraph 22 below will apply.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              21 For residents in the United States - BINDING ARBITRATION AND
              CLASS ACTION WAIVER:
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              READ THIS SECTION CAREFULLY. IT MAY SIGNIFICANTLY AFFECT YOUR
              LEGAL RIGHTS, INCLUDING WAIVING YOUR RIGHT TO FILE A LAWSUIT IN
              COURT OR TO PURSUE CLAIMS IN A CLASS OR REPRESENTATIVE CAPACITY.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              21.1 The BINDING ARBITRATION AND CLASS ACTION WAIVER provisions in
              this paragraph 21 apply to you if you are domiciled in and/or
              played the games or acquired and used Services in the United
              States. See JURISDICTION AND APPLICABLE LAW below for details. (9
              U.S.C. §1, et seq.). The BINDING ARBITRATION AND CLASS WAIVER is
              governed by the Federal Arbitration Act (9 U.S.C. §1, et seq.)
              (&quot;FAA&quot;) and federal arbitration law.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              21.2 The BINDING ARBITRATION AND CLASS ACTION WAIVER provisions in
              this paragraph 21 apply to all Disputes between you and
              Dmultiversegames and/or Dmultiversegames&#39;s Affiliates relating
              to the Games and Services. Paragraph 21 also applies to any
              Dispute (as defined below) between you and any Dmultiversegames
              Affiliates, each of which is an intended third-party beneficiary
              of paragraph 21. &quot;Affiliate&quot; means any entity
              controlling, controlled by or under common control with
              Dmultiversegames, where &#39;control&#39; means the direct or
              indirect ownership of more than fifty percent (50%) of such
              entity&#39;s capital or equivalent voting rights.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              21.3 To the fullest extent allowed by applicable law, you and we
              agree to submit all Disputes between us to individual, binding
              arbitration pursuant to the provisions in this paragraph 21. A
              &quot;Dispute&quot; means any dispute, claim, or controversy
              (except those specifically exempted below) between you and
              Dmultiversegames and/or Dmultiversegames&#39;s Affiliates that in
              any way relates to or arises from any aspect of our relationship,
              including, without limitation, your use or attempted use of the
              Services, all marketing related to the Services, all Services,
              Virtual Content, contests, tournaments, and all matters relating
              to or arising from these Terms (including Dmultiversegames&#39;s
              Privacy Policy and all other terms incorporated into these Terms)
              or any other agreement between you and Dmultiversegames, including
              any disputes over the validity or enforceability of these Terms to
              arbitrate. A Dispute shall be subject to these BINDING ARBITRATION
              AND CLASS ACTION WAIVER provisions regardless of whether it is
              based in contract, statute, regulation, ordinance, tort (including
              fraud, misrepresentation, fraudulent inducement, or negligence),
              warranty or any other legal or equitable theory. This includes
              Disputes, claims, or requests for relief arising at any time,
              including those that accrued before you entered into these Terms.
              You understand that there is no judge or jury in arbitration and
              that court review of an arbitration award is limited.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              21.4 Initial Dispute Resolution: If you have any concerns or
              queries regarding our Services, our customer support team can be
              reached through our Dmultiversegames Community portal (
              <span className="underline text-blue-500">
                http://community.dmultiversegames.com
              </span>
              ). Most concerns are quickly resolved in this manner to our
              customers&#39; satisfaction. In an effort to accelerate resolution
              and reduce the cost of any Dispute between you and
              Dmultiversegames and/or Dmultiversegames&#39;s Affiliates, you
              agree to first attempt to negotiate any Dispute informally for at
              least thirty (30) days before either party initiates any
              arbitration or court proceeding (the &quot;Initial Dispute
              Resolution Period&quot;). That period begins upon receipt of
              written notice from the party raising the Dispute. If
              Dmultiversegames has a Dispute with you, it will send notice of
              that Dispute to your billing address and email address you have
              provided to us. If you have a Dispute with Dmultiversegames and/or
              Dmultiversegames&#39;s Affiliates, you must notify us in writing
              at the following email address: 
              <span className="underline text-blue-500">
                queries@support.dmultiversegames.com
              </span>
              , using the subject line &quot;Initial Dispute Resolution
              Notice.&quot; Your notice of Dispute must be individual to you and
              must include your name, the screen name and email address
              associated with your player account (if any), and your residential
              address. The notice of Dispute also must describe the Dispute,
              explain the facts of the Dispute as you understand them, and tell
              Dmultiversegames what you want us to do to resolve the problem.
              The parties shall use their best efforts to settle any Dispute
              directly through consultation and good faith negotiations, and you
              agree that a notice of Dispute containing all of the information
              required above, followed by at least 30 days of good faith
              negotiation, are preconditions to either party initiating a
              lawsuit or arbitration. A notice of Dispute will not be valid,
              will not start the Initial Dispute Resolution Period, and will not
              allow you or Dmultiversegames later to initiate a lawsuit or
              arbitration, unless it contains all of the information required by
              this paragraph. If either of us commences an arbitration without
              having previously provided a valid and compliant notice of
              Dispute, you and Dmultiversegames agree that the applicable
              arbitration provider (or the arbitrator, if one has been
              appointed) must suspend the arbitration until the party that
              initiated it complies with the Initial Dispute Resolution Period.
              You and Dmultiversegames authorise the arbitration provider or the
              arbitrator to decide summarily whether the party that commenced an
              arbitration complied with the Initial Dispute Resolution Period
              requirement, relying solely on these Terms and the notice of
              Dispute (if any) that you or Dmultiversegames provided before
              commencing arbitration.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              21.5 Binding Arbitration and Arbitration Procedure: If a Dispute
              cannot be resolved through negotiations during the Initial Dispute
              Resolution Period, then either party may initiate binding
              arbitration as the sole means to formally resolve the Dispute,
              unless an exception applies as stated below. Except in the event
              of a Mass Arbitration (as defined below), the arbitration will be
              administered by JAMS in accordance with the JAMS Streamlined
              Arbitration Rules and Procedures (the &quot;JAMS Rules&quot;)
              effective as of the date of the Notice of Dispute, which are
              available at the JAMS website, 
              <span className="underline text-blue-500">
                http://www.jamsadr.com/rules-streamlined-arbitration
              </span>
              , as modified by these Terms. If, for any reason, JAMS is unable
              to provide the arbitration, then except as otherwise stated below,
              you may file your Dispute with any national arbitration company
              that handles consumer arbitrations following procedures that are
              substantially similar to the JAMS Rules. Arbitration hearings may
              be conducted by videoconference or telephone unless the arbitrator
              believes an in-person hearing is necessary. In such instances, the
              location of an arbitration hearing will be decided pursuant to the
              JAMS Rules. For players using Games from within the United States
              but who are not residents of the United States, arbitration will
              be initiated in Los Angeles County, California, and you and
              Activision agree to submit to the personal jurisdiction of any
              federal or state court in Los Angeles County, California, in order
              to compel arbitration, to stay proceedings pending arbitration, or
              to confirm, modify, vacate, or enter judgement on the award
              entered by the arbitrator. The arbitrator will be empowered to
              grant whatever relief would be available in a court under law or
              in equity. The arbitrator will make a decision in writing but need
              not provide a statement of reasons unless requested by a party.
              The arbitrator must follow applicable law. If the Dispute does not
              exceed $25,000, the arbitration will be conducted solely on the
              basis of written submissions. The parties may bring any
              dispositive motion or motions during the course of the
              proceedings. Discovery in the arbitration will be limited to that
              necessary to resolve the dispute. The decision of the arbitrator
              shall be final and binding on you and us, and any award of the
              arbitrator may be entered in any court of competent jurisdiction.
              The arbitrator and not any federal, state or local court or
              agency, has exclusive authority to determine the scope and
              enforceability of this arbitration agreement, including whether a
              Dispute is subject to arbitration as well as to decide all issues
              arising out of or relating to the interpretation, applicability,
              validity, arbitrability, enforceability and/or formation of this
              agreement to arbitrate, including, but not limited to, where a
              party raises as a defence to arbitration that the claims in
              question are exempted from the arbitration requirement or that any
              portion of this agreement is void, voidable, or not enforceable.
              If a lawsuit filed in court includes claims or requests for relief
              that are arbitrable and claims or requests for relief that are
              not, you and we agree that any non-arbitrable claims or requests
              for relief will be stayed pending the completion of the
              arbitration of the arbitrable claims or requests for relief.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              21.6 Class Action Waiver: TO THE FULLEST EXTENT ALLOWED BY
              APPLICABLE LAW, YOU AND WE AGREE THAT EACH PARTY MAY BRING
              DISPUTES AGAINST THE OTHER PARTY ONLY IN AN INDIVIDUAL CAPACITY,
              AND NOT AS A CLASS ACTION, COLLECTIVE ACTION OR CLASS ARBITRATION,
              OR AS A PRIVATE ATTORNEY GENERAL. To the fullest extent applicable
              law does not permit waiver of private attorney general claims, but
              permits them to be arbitrated, then such claims shall be resolved
              in arbitration. If any court or arbitrator determines that the
              class action waiver set forth in this paragraph is void or
              unenforceable for any reason or that an arbitration can proceed on
              a class basis, the arbitration provision set forth above shall be
              deemed null and void in its entirety and the parties shall be
              deemed to have not agreed to arbitrate disputes. IF FOR ANY REASON
              A CLAIM PROCEEDS IN COURT RATHER THAN THROUGH ARBITRATION, YOU
              AGREE THAT, TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, NO
              ACTION WILL BE BROUGHT ON A CLASS OR COLLECTIVE BASIS AND YOU
              UNCONDITIONALLY WAIVE ANY RIGHT TO BRING SUIT ON A CLASS OR
              COLLECTIVE BASIS.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              21.7 Exception - Litigation of Intellectual Property and Small
              Claims Court claims: Notwithstanding the parties&#39; decision to
              resolve all disputes through arbitration, either party may bring
              an action in state or federal court that only asserts claims for
              patent infringement or invalidity, copyright infringement, moral
              rights violations, trademark infringement, and/or trade secret
              misappropriation, but not, for clarity, claims related to the
              licence granted to you for the Services under these Terms. Either
              party may also seek relief in a small claims court for any
              individual Disputes within the scope of that court&#39;s
              jurisdiction. If an arbitration is filed, before the arbitrator is
              formally appointed either party can send written notice to the
              opposing party and the applicable arbitration provider that it
              wants the case decided by a small claims court, after which the
              arbitration provider must close the case.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              21.8 Exception – Mass Arbitration Before FedArb: Notwithstanding
              the parties&#39; decision to have arbitrations administered by
              JAMS, if 20 or more demands for arbitration are filed relating to
              the same or similar subject matter and sharing common issues of
              law or fact, and counsel for the parties submitting the demands
              are the same or coordinated, you and we agree that this will
              constitute a &quot;Mass Arbitration.&quot; If a Mass Arbitration
              is commenced, you and we agree that it will not be governed by
              JAMS Rules or administered by JAMS. Instead, a Mass Arbitration
              will be administered by FedArb, a nationally recognized
              arbitration provider, and governed by the FedArb Rules in effect
              when the Mass Arbitration is filed, excluding any rules that
              permit arbitration on a class- wide basis (the &quot;FedArb
              Rules&quot;), and under the rules set forth in these Terms. The
              FedArb Rules are available at https://www.fedarb.com/ or by
              calling 1-650-328-9500. You and we agree that the Mass Arbitration
              will be resolved using FedArb&#39;s Framework for Mass Arbitration
              Proceedings ADR-MDL, available at https://www.fedarb.com/. Before
              any Mass Arbitration is filed with FedArb, you and we agree to
              contact FedArb jointly to advise that the parties intend to use
              FedArb&#39;s Framework for Mass Arbitration Proceedings ADR-MDL.
              The individual demands comprising the Mass Arbitration will be
              submitted on FedArb&#39;s claim form(s) and as directed by FedArb.
              You and Activision agree that if either party fails or refuses to
              commence the Mass Arbitration before FedArb rather than JAMS, you
              or Activision may seek an order from JAMS compelling compliance
              and directing administration of the Mass Arbitration before
              FedArb. Pending resolution of any such requests, you and we agree
              that all arbitrations comprising the Mass Arbitration (and any
              obligation to pay arbitration fees) will be stayed.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              21.9 30 Day Right to Opt Out: You have the right to opt-out and
              not be bound by the arbitration agreement and class action waiver
              provisions in in this paragraph 21 by sending written notice of
              your decision to opt-out to the following address:
              Dmultiversegames.com Ltd Aragon Business Centre Level 4, Dragonara
              Road, St Julian&#39;s STJ3140, Malta, Attn: Dmultiversegames
              Legal. The notice must be sent within 30 days of the earlier of
              your first download of the applicable Game or when you first use
              or access the applicable Service; otherwise you will be bound to
              arbitrate Disputes in accordance with the terms of those
              paragraphs. If you opt-out of these arbitration provisions, we
              also will not be bound by them. If you previously opted out of
              prior versions of Paragraph 21, then you will be bound to this
              Paragraph 21 unless you comply with the opt-out requirements. You
              must opt out within 30 Days after these Terms become effective on
              the earliest of: (1) the date on which you first download the
              applicable Game; or (2) accessed or commenced use of the
              applicable Service.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              21.10 The provisions of this paragraph 21 will govern, control,
              and supersede, any inconsistent provisions of these Terms, any
              previous versions of these Terms, or any terms or conditions of
              any agreement that incorporates these Terms or is incorporated by
              these Terms .
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              22 JURISDICTION AND APPLICABLE LAW
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              22.1 Except for paragraph 21, paragraph 22.2 and paragraph 22.3,
              and unless the law of your country of residence states otherwise,
              the laws of England will govern the interpretation of these Terms
              and apply to claims for breach of it. If there is a Dispute
              between us regarding these Terms, then that Dispute will be
              subject to the jurisdiction of the courts of England unless the
              law in your country of residence allows you to choose the courts
              of that country for the Dispute in question. If you are resident
              in a member state of the European Union, you also enjoy the
              protection of the mandatory provisions of the consumer protection
              laws in your member state.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              22.2 For residents in the United States: You and we irrevocably
              consent to the exclusive jurisdiction and venue of state or
              federal courts in Los Angeles County, California to resolve any
              claims that are subject to exceptions to the arbitration agreement
              described in paragraph 21 above, or otherwise determined not to be
              arbitrable. To the fullest extent permitted by law, any claim or
              request for relief in a demand for arbitration filed pursuant to
              paragraph 21 of these Terms, as well as any claim or request for
              relief in a lawsuit filed in court under an exception to the
              arbitration agreement in paragraph 21, will be barred if filed
              more than two (2) years after the date that the claim or request
              for relief accrued. If the law requires a claim or request for
              relief be filed earlier than two (2) years after the claim or
              request for relief accrued, that law controls.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              22.3 If you are a resident outside the United States and you are
              entitled to commence and/or participate in legal proceedings
              within the United States, then you agree that you will be bound by
              the BINDING ARBITRATION AND CLASS ACTION WAIVER provisions in
              paragraph 21 above.
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              22.4 You understand and agree that our Website, Games and other
              Services may not be used, accessed, downloaded, or otherwise
              exported, reexported, or transferred in contravention of
              applicable export control, economic sanctions, and import laws and
              regulations, including, but not limited to, the U.S. Export
              Administration Regulations (&quot;EAR&quot;) and regulations
              promulgated by the U.S. Department of the Treasury&#39;s Office of
              Foreign Assets Control (&quot;OFAC&quot;). You represent and
              warrant that you (1) are not subject to U.S. sanctions or export
              restrictions and otherwise are eligible to utilise our Website,
              Games and other Services under applicable laws and regulations;
              (2) are not located or ordinarily resident in a country or region
              subject to comprehensive or near- comprehensive U.S.
              sanctions/embargo, unless your use of our Website, Games and other
              Services in such country or region is authorised by U.S. law; (3)
              are not an official, employee, agent, or contractor of, or
              directly or indirectly acting or purporting to act for or on
              behalf of, a government (including any political subdivision,
              agency, or instrumentality thereof or any person directly or
              indirectly owned or controlled by the foregoing) or political
              party (e.g., Cuban Communist Party, Workers&#39; Party of Korea)
              subject to U.S. sanctions/embargo or any other entity in a
              sanctioned/embargoed country or region or subject to U.S.
              sanctions/embargo; and (4) will not use our Website, Games and
              other Services in connection with an end-use prohibited by U.S.
              law.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              23 Questions about these Terms
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              23.1 If you have any questions about these Terms or our Services
              you may contact us by email at 
              <span className="underline text-blue-500">
                queries@support.dmultiversegames.com
              </span>
              .
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              These Terms were last updated on 02.10.2023
            </p>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              Appendix 1: A. Information concerning the exercise of the right of
              withdrawal for residents of the European Economic Area
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-2xl p-8">
              Right of Withdrawal
            </h2>
            <p className="text-lg leading-8 text-gray-300 px-8 pt-4">
              If you are resident in the European Economic Area and you have
              purchased a Subscription from us, you have the right to withdraw
              from your purchase within 14 calendar days without giving any
              reason. This right of withdrawal does not apply to renewals of
              your existing Subscription. The withdrawal period will expire
              after 14 calendar days from the day after your purchase under
              these Terms or, if you made the purchase through a third-party,
              the purchase contract with the third-party retailer. If you made
              the purchase directly through us, to exercise the right of
              withdrawal, you must inform us (Legal Team, Activision Blizzard UK
              Ltd of The Ampersand Building, 178 Wardour Street, London, W1F
              8FY, United Dmultiversegamesdom or via 
              <span className="underline text-blue-500">
                https://soporto.dmultiversegames.com
              </span>
              ) of your decision to withdraw from your purchase by an
              unequivocal statement (e.g. a letter sent by post or e-mail). You
              may use the model withdrawal form attached hereafter, but it is
              not obligatory. To meet the withdrawal deadline, it is sufficient
              for you to send your communication concerning your exercise of the
              right of withdrawal before the withdrawal period has expired.
              Certain exceptions and requirements apply to your right of
              withdrawal. If your purchase was through a third-party retailer
              (like Google or Apple), we recommend contacting them directly for
              information on your options for withdrawal. If you withdraw from
              the purchase made directly through us, we will reimburse to you
              all payments received from you under the purchase without undue
              delay and in any event not later than 14 calendar days from the
              day on which we receive your decision to withdraw. Please note
              that if you requested to begin the performance of any
              Subscription(s) you purchase during the withdrawal period, you are
              only entitled to a refund that is proportionate to the portion of
              the Subscription(s) you&#39;ve already received. For example, if
              you have already received the Subscription for three days prior to
              your request to withdraw, you will be entitled to receive a refund
              for the full coverage of the Subscription, minus the cost for
              receiving three days of the Subscription. We won&#39;t charge you
              any fees as a result of this refund. If your purchase was through
              a third- party retailer, they will carry out the refund and we
              recommend contacting them for more information. We will carry out
              such reimbursement using the same means of payment as you used for
              the initial transaction, unless you have expressly agreed
              otherwise; in any event, you will not incur any fees as a result
              of such reimbursement. Please note you are not entitled to a
              refund for any Subscriptions which have already been fully
              performed prior to your request for withdrawal. For the avoidance
              of doubt, you agree that when you purchase Virtual Goods and/or
              Virtual Money from us, you request immediate performance and that
              you understand this means you will lose your statutory right to
              withdraw from your purchase and the applicable terms once you
              access and use the Virtual Goods and/or Virtual Money.
              <ul className="list-decimal list-inside p-4">
                <li>
                  <strong>
                    Model withdrawal Form for residents of the European Economic
                    Area
                  </strong>
                  (Complete and return this form only if you wish to withdraw
                  from your purchase. We also strongly recommend that you submit
                  this form to the retailer or platform partner (with the
                  relevant information about them filled out below) who you
                  purchased your Games and/or Subscription from in order to
                  withdraw from the purchase.)
                  <br></br>
                  <br></br>
                  To Legal Team, Activision
                  <br></br>
                  Blizzard UK Ltd of The Ampersand Building, 178 Wardour Street,
                  London, W1F 8FY, United Dmultiversegamesdom:
                  <br></br>
                  I/We hereby give notice that I/We withdraw from my/our
                  contract for the purchase of the following goods/ the
                  provision of the following services:*
                  <br></br>- Ordered/ received on*:
                  <br></br> - Name of consumer(s):
                  <br></br>- Address of consumer(s):
                  <br></br> - Signature of consumer(s): (only required if this
                  form is notified on paper)
                  <br></br> - Date: (*) Delete where appropriate.
                  <br></br>
                </li>
                <li>
                  <strong>
                    Statutory guarantees under French law for residents in
                    France regarding the Games and Virtual Content:
                  </strong>
                  French law grants you as consumers the following statutory
                  rights and guarantees in relation to the Games and Virtual
                  Content. We will therefore offer you the protection granted by
                  law under the legal warranty of conformity provided by
                  articles L. 224-25-12 to L. 224-25-26 of the French Consumer
                  Code. We are liable for supplying digital content and services
                  that comply with these Terms and with the objective and
                  subjective criteria set out by the applicable law. Regarding
                  one-off supply, we are accountable for any non-conformity that
                  exists at the time of supply and which becomes apparent within
                  two years of supply. Regarding digital content or service
                  supplied on a continuous basis, we are accountable for any
                  non-conformity which becomes apparent during the period in
                  which it is supplied under these Terms and your applicable
                  purchase terms. We are also accountable, within the same time
                  limits, for any non-conformity resulting from the incorrect
                  integration of the digital content or service into your
                  digital environment where this has been done by us or under
                  our responsibility, or where the incorrect integration by you
                  is the result of deficiencies in the instructions provided by
                  us. For the 12 month period following the supply of any Games
                  and/or Virtual Content to you, you will not be required to
                  prove the existence of the defect. In the event of lack of
                  conformity, you will be entitled to have the digital content
                  or service brought into conformity or, failing that, to have
                  the price reduced or to cancel these Terms and/or the
                  associated purchase terms (as relevant) under the conditions
                  set out in the French Consumer Code.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsOfService;
