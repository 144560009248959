import GameGrid from "../components/GameGrid";
import HeroBanner from "../components/HeroBanner";
import Notification from "../components/Notification";

function Home() {
  return (
    <>
      <HeroBanner />
      <GameGrid />
      <Notification />
    </>
  );
}

export default Home;
